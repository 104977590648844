<template>
    <div id="app">
        <div class="header" :hidden="is_alipay||is_wxpay">
            <van-nav-bar :title="state == 0?'付款失败':'付款成功'" left-arrow @click-left="closewin">
                <template #left>
                    <van-icon name="cross" size="20" color="#000000"/>
                </template>
            </van-nav-bar>
        </div>
        <div class="content pay-result">
            <van-image fit="contain" :src="state == 0?state_fail:state_successful" width="80"
                       class="pay-result-img"></van-image>
            <div class="pay-result-text">{{state == 0?'付款失败':'付款成功'}}</div>
            <div v-if="state == 0" class="fail-reason">{{msg}}</div>
            <div class="timer" v-if="state == 0">
                <div>剩余付款时间：</div>
                <div id="timer">{{timer}}</div>
            </div>
            <div v-else class="success-desc">您已向商家 {{store_info.shopName}} 支付</div>
            <div v-if="state == 0" style="padding: 0px 68px;">
                <van-button type="default" class="order-pay-platform" round block @click="goBack">其它支付方式</van-button>
                <van-button color="#ef2505" style="margin-top: 20px;" v-if="timer!='00:00:00' && btn_show" type="default" round block @click="payOrder">继续支付</van-button>
            </div>
            <div v-else class="money">￥{{amount}}</div>

        </div>
        <div class="footer">
            <div class="zft-ad" @click="download">
                <div class="zft-ad-title">广告位</div>
                <div class="zft-ad-desc">推荐使用芝富通APP付款，更优惠！<br/><!--每单最高可减50元--></div>
                <div class="download-now">立即下载</div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        Image,
        NavBar,
        Icon,
        Button
    } from 'vant'

    export default {
        name: "index.vue",
        components: {
            [Image.name]: Image,
            [NavBar.name]: NavBar,
            [Icon.name]: Icon,
            [Button.name]: Button
        },
        data() {
            return {
                state: 1,
                state_successful: require("../../assets/state_successful.png"),
                state_fail: require("../../assets/state_fail.png"),
                store_info:{},
                amount:'',
                orderNo:'',
                timer:'00:00:00',
                btn_show:true,
            }
        },
        mounted() {
            // this.state = this.$route.params.state
            this.init()
        },
        methods: {
            init(){
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
                document.body.appendChild(s);
                const _this = this;
                // eslint-disable-next-line no-undef
                window.onload = () => {
                    this.ready(function () {
                        alert('动态载入成功');
                        _this.btn_show = true;
                    })
                }
                    const query = this.$route.params;
                // window.document.title = query.status == 'TRADE_SUCCESS'?'付款成功':'付款失败'
                // this.state = query.status == 'TRADE_SUCCESS'?1:0
                this.state = query.state
                this.store_info = query.store_info
                this.amount = query.amount
                this.uid  = query.uid
                this.msg = query.msg
                this.orderNo = query.orderNo
                if(this.state === 0){
                    const params = {
                        orderNo:query.orderNo
                    }
                    let _this = this;
                    this.$request.get('sys/order/getOrderPayInfoByOrderId',{params}).then((res)=>{
                        console.log(res)
                        if(parseInt(res.code) === 200){
                            _this.result = res.data
                            // alert(JSON.stringify(_this.result))

                            const timer = setInterval(function () {
                                const date = new Date();
                                var ts =(res.data.expireTime-date.getTime())/1000;//计算剩余的毫秒数
                                console.log(ts);
                                var hh = parseInt(ts  / 60 / 60 % 24, 10);//计算剩余的小时数
                                var mm = parseInt(ts  / 60 % 60, 10);//计算剩余的分钟数
                                var ss = parseInt(ts  % 60, 10);//计算剩余的秒数
                                hh = _this.checkTime(hh);
                                mm = _this.checkTime(mm);
                                ss = _this.checkTime(ss);
                                if(ts>0){
                                    // document.getElementById("timer").innerHTML = hh + ":" + mm + ":" + ss;
                                    _this.timer = hh + ":" + mm + ":" + ss;
                                }else if(ts<0){
                                    // document.getElementById("timer").innerHTML="00:00:00";
                                    // location.reload();
                                    _this.timer = '00:00:00'
                                    clearInterval(timer)
                                    clearInterval(timer)
                                    clearInterval(timer)
                                }
                            }, 1000)
                        }
                    })
                }
                /*
                */
            },
            checkTime(i) {
                if (i <= 10) {
                    i = "0" + i;
                }
                return i;
            },
            closewin() {
                if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
                    window.location.href = "about:blank";
                    window.close();
                } else {
                    window.opener = null;
                    window.open("", "_self");
                    window.close();
                }
            },
            payOrder(){
                let _this = this;
                // alert(JSON.stringify(_this.result))
                // eslint-disable-next-line no-undef
                ap.tradePay({
                        tradeNO: _this.result.payUrl
                    }, function({resultCode}){

                    if(resultCode == '9000'){
                        _this.state = 1;
                        // _this.$router.replace({name:'success',params:{state:1,amount:_this.amount,store_info:_this.store_info,uid:_this.storeId}})
                    }else{
                        let msg = ''
                        if(resultCode == '8000'){
                            msg = '正在处理中'
                        }else if(resultCode == '4000'){
                            msg = '订单支付失败'
                        }else if(resultCode == '6001'){
                            msg = '用户中途取消'
                        }else if(resultCode == '6002'){
                            msg = '网络连接出错'
                        }else if(resultCode == '99'){
                            msg = '用户点击忘记密码导致快捷界面退出'
                        }
                        _this.msg = msg;
                        // _this.$router.replace({name:'success',params:{state:0,amount:_this.amount,store_info:_this.store_info,uid:_this.storeId,msg:msg,orderNo:_this.orderNo}})
                    }
                })
            },
            goBack(){
                // const  url = 'https://www.zhimadi.cn/getcode.php?auk=wallet&id='+this.uid;
                this.$router.back()
                // this.$router.push('home?id=')
                // location.href =
                // window.open('https://www.zhimadi.cn/getcode.php?auk=wallet_local&id='+this.result.userCollectionId,"_blank")
                // window.location.href = url
                // window.location.replace(url)
                // var a = document.createElement("a");
                // a.setAttribute("href", 'https://www.zhimadi.cn/getcode.php?auk=wallet_local&id='+this.result.userCollectionId);
                // a.setAttribute("target", "_blank");
                // a.click();
            },
            download(){
                this.$router.push('download')
            }
        }
    }
</script>

<style scoped lang="less">
    #app {
        text-align: center;
        background: white;
        height: 100vh;
    }

    .footer {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .zft-ad {
        position: relative;
        background: #e5e5e5;
        margin: 16px;
        padding: 12px 0px;
        border-radius: 6px;
        color: #888888;

        &-desc {
            margin-top: 10px;
        }
    }

    .download-now {
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 12px;
        background: #000000;
        opacity: .3;
        border-radius: 12px 0px 6px 0px;
        padding: 2px 6px;
    }

    .pay-result {
        &-img {
            margin-top: 30px;
        }

        &-text {
            margin-top: 16px;
            font-weight: bold;
        }

        .fail-reason {
            margin-top: 10px;
        }

        .success-desc {
            margin-top: 40px;
            color: #888888;
        }

        .order-pay-platform {
            margin-top: 30px;
        }

        .money {
            margin-top: 10px;
            line-height: 40px;
            font-size: 30px;
        }
    }
    .timer{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        line-height: 22px;
    }
    #timer{
        color: #ef2505;
    }

</style>
